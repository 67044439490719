<template>

  <div v-if="$can('create', abilityRequired)">

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="$store.state.alerts.fetchingList"
              @click="refreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              :to="{ name: 'apps-alerts-manage' }"
            >
              <feather-icon icon="PlusIcon" /><span class="text-nowrap ml-1">Add Alert</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mt-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchListItems"
        responsive
        :fields="tableColumns"
        primary-key="SK"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: detail-->
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-alerts-manage', params: { SK: data.item.SK } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.detail.slice(0, 80) + '...' }}
            </b-link>
          </div>
        </template>
        <!-- Column: creation_date -->
        <template #cell(creation_date)="data">
          <div class="text-nowrap">
            {{ data.item.creation_date.replace('T', ' ') }}
          </div>
        </template>
        <!-- Column: is_shown -->
        <template #cell(is_shown)="data">
          <b-badge
            pill
            :variant="`light-${resolveAlertsStatusVariant(data.item.is_shown)}`"
            class="text-capitalize"
          >
            {{ data.item.is_shown }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('update', 'alerts')"
              :to="{ name: 'apps-alerts-manage', params: { username: data.item.SK } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'alerts')"
              @click="deleteListItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div
        v-if="$store.state.alerts.fetchingList"
        class="d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- Pagination -->
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BPagination, BDropdownItem, BDropdown, BBadge, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'
// import { ref, watch, onUnmounted } from '@vue/composition-api'
import { ref, onUnmounted } from '@vue/composition-api'
// import { useRouter } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useList from './useList'
import storeModule from '../storeModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BDropdownItem,
    BDropdown,
    BBadge,
    BSpinner,

    vSelect,
  },
  setup() {
    const abilityRequired = 'alerts'

    // Get the current user's data
    const userData = getUserData()

    // Register module
    const STORE_MODULE_NAME = 'app-alerts'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Setup other vars
    const isAddNewSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Unapproved', value: false },
      { label: 'Approved', value: true },
    ]

    const {
      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
      resolveAlertsStatusVariant,
      isLoading,

      clientList,
    } = useList(abilityRequired, STORE_MODULE_NAME)

    return {
      abilityRequired,
      userData,
      // Sidebar
      isAddNewSidebarActive,

      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      statusOptions,
      clientList,
      resolveAlertsStatusVariant,
      isLoading,
    }
  },
  data() {
    return {
      isLoading: true, // Set to true to show spinner initially
    }
  },
  created() {
    this.$store.state.alerts.fetchingList = true
  },
  methods: {
    refreshList() {
      this.$store.state.alerts.fetchingList = true
      this.refetchData()
    },
    deleteListItem(item) {
      console.log(item)
      if (this.$ability.can('delete', 'clients')) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete the Alert.', {
            title: 'Delete Alert',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch('app-alerts/deleteItem', item)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Alert deleted successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                  this.refreshList()
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error deleting Alert. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
