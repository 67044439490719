import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useList(abilityRequired, appStoreModuleName) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'detail', sortable: false },
    { key: 'creation_date', label: 'Creation Date', sortable: false },
    { key: 'is_shown', label: 'Show', sortable: false },
    { key: 'actions', sortable: false },
  ]

  const UIsettings = JSON.parse(localStorage.getItem('UIsettings'))

  const perPage = ref(10)
  if (UIsettings !== null && typeof UIsettings.alertsPerPage !== 'undefined') {
    // get the current value for per page
    perPage.value = UIsettings.alertsPerPage.value
  }

  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('creation_date')
  const isBusy = ref(false)
  const isSortDirDesc = ref(true)
  const theList = ref(null)
  const theListComplete = ref(null)
  const isLoading = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    console.log('refetchData')
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    console.log('Filter Watch Triggered')
    UIsettings.alertsPerPage = perPage
    localStorage.setItem('UIsettings', JSON.stringify(UIsettings))
    refetchData()
  })

  const fetchListItems = (ctx, callback) => {
    console.log('fetchListItems')
    console.log(ctx)
    if (ability.can('read', abilityRequired)) {
      console.log('PASSED')
      const qvars = {
        forceRefresh: true,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      }
      console.log(qvars)
      store
        .dispatch(`${appStoreModuleName}/fetchItems`, qvars)
        .then(response => {
          const { theItems } = response.data
          console.log(callback)
          callback(theItems)
          theListComplete.value = theItems
          totalItems.value = response.data.totalItems
        })
        .catch(error => {
          console.log(error) // No current user
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // Get the list of clients
  const qvars = {}
  qvars.status = 'active'
  qvars.q = null
  qvars.service = null

  const resolveAlertsStatusVariant = status => {
    if (status === true || status === 'true') return 'success'
    if (status === false || status === 'false') return 'secondary'
    return 'success'
  }

  return {
    fetchListItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    theList,
    theListComplete,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,
    resolveAlertsStatusVariant,
    isLoading,
  }
}
